<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../../helper/Gen';

	export default {
		extends: BaseVue,
		data() {},
		mounted() {},
		methods: {},
		watch: {},
	};
</script>

<template>
	<section id="content">
		<div class="content-wrap">
			<section class="section nobg pt-lg-0 mt-lg-5" id="notFound">
				<div class="container">
					<div class="row">
						<div class="col-md-6">
							<div class="nf-desc">
								<div class="heading-block mb-4">
									<h1>404</h1>
								</div>
								<h3>Kami tidak dapat menemukan halaman yang Anda cari.</h3>
								<div class="nf-action">
									<h4 class="text-brown mb-3">Dapatkan Produk Terbaru Tropicana Slim<br>di Nutrimart Official Store</h4>
									<a href="https://www.nutrimart.co.id/brands/tropicana-slim.html" target="_blank" class="primary-btn">Beli Sekarang</a>
								</div>
								<router-link :to="{name:'Index'}">
									<i class="icon-angle-left mr-2"></i>Kembali ke Beranda
								</router-link>
							</div>
						</div>
						<div class="col-md-6">
							<div class="prod-detail mt-5 mt-lg-0">
								<div class="pd-img">
									<div class="pd-img-bg" 
									:style="'background-image:url('+assets('fo_images','product-bg.png')+')'"></div>
									<img :src="assets('fo_images','prod-detail.png')" alt="Tropicana Slim Classic">
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</section>
</template>